@import 'app/styles/variables';
@import 'app/styles/fonts/gt-walsheim';

@import '../../common/src/lib/styles/material/nbkc-theme';
@import 'colors';

@import 'app/styles/ag-grid';
@import 'app/styles/mat-paginator';
@import 'app/styles/mat-select';
@import 'app/styles/mat-snackbar';
@import 'app/styles/mat-dialog-container';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// removing the outline normalize.css adds
button:focus {
	outline: none;
}

div,
body,
span,
p {
	font-family: 'GT Walsheim';
}

::-webkit-scrollbar {
	width: 12px;
	height: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	border-right: 5px white solid;
	background-color: rgba(199, 200, 195);
}

.rounded-xl-dialog {
	.mat-mdc-dialog-surface {
		border-radius: 16px !important;
	}

	.mat-mdc-dialog-container {
		--mdc-dialog-container-shape: 16px !important;
	}
}
