.dialog-container .mat-dialog-container {
	border-radius: 10px;
	padding: 15px;
	overflow-x: hidden;
	display: flex;

	.withdraw-dialog {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;

		.subheader {
			font-weight: 100;
			color: rgba(109, 112, 115, 1);
		}

		.mat-dialog-content {
			flex-grow: 1;
		}

		.actions {
			padding: 0;
		}
	}
}
