@import 'ag-grid-community/styles/ag-grid.min.css';
@import 'ag-grid-community/styles/ag-theme-material.min.css';

.ag-theme-material {
	.ag-header-cell-text {
		overflow: visible;
		font-size: 1.4em;
	}

	.ag-row {
		height: 60px;

		.ag-cell {
			display: flex;
			align-items: center;
		}
	}

	.ag-cell-value {
		overflow: visible;
		font-size: 1.1em;
	}

	.ag-body-viewport {
		overflow-x: hidden;
	}

	.ag-filter-apply-panel {
		display: flex;
		justify-content: space-between;
		margin-right: 16px;
	}

	// sets the min-height of the grid when no rows are displayed
	.ag-center-cols-clipper {
		min-height: 250px !important;
	}
}
