@import 'colors';

.radio-chip.mat-mdc-chip.mat-mdc-standard-chip {
	color: $nbkc-medium-gray;
	background-color: white;
	border: 1px solid $nbkc-medium-gray;
	/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
	&.mat-chip-selected {
		border: none;
		color: white !important;
	}
}
