@use "sass:color";
@import "colors";

.mat-mdc-menu-panel.userMenu {
	position: fixed;
	top: 5rem;
	right: 1.5rem;
	.mat-mdc-menu-content {
		margin: 0 0.25rem;
	}
	.mat-mdc-menu-item {
		font-family: "GT Walsheim", 'Arial', sans-serif;

		&.cdk-focused:not([disabled]) {
			background: color.adjust($nbkc-blue, $alpha: -0.88);
		}
	}
}
