.grid-actions {
	.mat-mdc-form-field {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.mat-mdc-form-field-wrapper {
		padding: 0;
		margin: 0;
	}

	.mat-mdc-form-field-appearance-outline .mat-form-field-outline {
		color: rgba(160, 174, 192, 1);
	}
}
