.mat-paginator-page-size-label, .mat-paginator-range-label {
	font-size: larger;
	opacity: .75;
}

.mat-paginator-range-actions {
	padding-left: 2em;
}

.mat-paginator .mat-select-value {
	text-align: center;
}

.mat-form-field-underline {
	display: none;
}
