// TODO: remove after Bluefin Upgrade
.big {
	.mat-checkbox-label {
		font-weight: 400;
	}
	.mat-checkbox-inner-container {
		height: 25px;
		width: 25px;
		margin: 0 10px 0 0;
	}

	.mat-checkbox {
		.mat-checkbox-ripple {
			left: calc(50% - 25px);
			top: calc(50% - 25px);
			height: 50px;
			width: 50px;
		}
	}
}

// These changes were for the move to angular 16 (and updated material) to keep our specific NBKC styles in play, it looks like at some point material moved to "mdc" vs "mat".
// At some point the mat* stuff should be able to come out but it was safer to leave until post Bluefin simplification project

.big {

	.mdc-checkbox{
		align-self:baseline;
		flex: 0 0 25px;
		width:25px;
		height:25px;
		--mdc-checkbox-state-layer-size: 25px;
		margin-right:10px;
	}

	.mdc-checkbox {
		.mdc-checkbox__ripple {
			left: calc(50% - 25px);
			top: calc(50% - 25px);
			height: 50px;
			width: 50px;
		}

		.mdc-label {
			font-weight: 400;
		}
	}

	.mdc-checkbox__background{
		width:25px;
		height:25px;
	}

	.mdc-checkbox .mdc-checkbox_background{
		top: calc((var(--mdc-checkbox-state-layer-size) - 25px) / 2);
		left: calc((var(--mdc-checkbox-state-layer-size) - 25px) / 2);
	}

}

.wrap-text{
	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-layout {
		white-space: initial;
	}
}
