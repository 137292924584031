$nbkc-blue: #1075f1; /* primary */
$nbkc-primary-light-blue: #edf4fc; /*  * primary #2 * background-color in select-container, icon background */
$nbkc-light-blue: #338fd8; /* background-color in actions-renderer. Not used elsewhere. */
$nbkc-light-blue-alt: #224c80; /*  * primary #3 background-color in actions-renderer and left nav  */
$light-blue: #338fd8; /* same as nbkc-light-blue; used in theme  */
$light-gray: #c9c9c9; /*  border-bottom on divider, review, availability;  */
$nbkc-medium-gray: #8d9192; /*  common color, used many places  */
$nbkc-medium-gray-alt: #a4a4a4; /* subtitle on generic header component  */
$nbkc-light-gray: #c7c8c3; /*  password-validator validation requirements, border on hr between uploaded documents */
$nbkc-dark-gray: #6d7073; /*   *700 neutral* common color, used many places (in CS, text-nbkc-medium-gray common, but I can't find the class definition) */
$nbkc-red: #dd2c00; /* validation messages */
$nbkc-green: #44cf6c; /*  progress bar, validation, doc upload, to-do list */
$nbkc-bright-green: #10f175; /*  card success icons */

$yellow: #f9e15b; /* mat-snackbar */
$icon-blue: #1075f1; /*  same as nbkc blue, in C#? text-nbkc-icon-blue */
$nbkc-dark-blue: #2f3b4b; /*  left nav component background color */
$not-white: #f7f7f7; /*  * 000 neutral * background color: availability, header; color: actions button *000 neutral* */
$not-black: #151617; /*  * 900 neutral * color: toggle, identity-verification, manual-upload, password validator, product-header, profile-header, text, availability, to-do list */
$less-gray: #84878a; /*  * 600 neutral * unused Exchange for nbkc-medium-gray? */
$light-gray: #b5b9bd; /*  * 400 neutral * color: mat-input, border on many */

/**
/* pipelines colors some are different for some reason???
/*  $nbkc-blue: #0661a8;
/*  $green: #00ab56
**/
