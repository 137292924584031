@import 'colors';

input:focus.mat-mdc-input-element {
	border:none;
}
input:hover.mat-mdc-input-element {
	border:none;
}

input.mat-mdc-input-element {
	font-size: 1rem !important;
}

.no-bottom-padding {
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
	color: $light-gray !important;
}


.short.mat-form-field-appearance-outline{
	&.mat-mdc-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix  { padding: 0.3rem 0 0.5rem 0; }
	&.mat-mdc-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper { top: -1.4rem; }
	&.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
		transform: translateY(-1.1em) scale(.75) !important;
		width: 133.33333% !important;
	}
	.mat-form-field-outline-thick {
		.mat-form-field-outline-end, .mat-form-field-outline-gap, .mat-form-field-outline-start{
			border-width: 1px;
		}
	}
	&.mat-focused{
		.mat-form-field-outline-thick {
			.mat-form-field-outline-end, .mat-form-field-outline-gap, .mat-form-field-outline-start{
				border-width: 2px;
			}
		}
	}
	.mat-form-field-subscript-wrapper{
		margin-top: .5rem;
	}
	.mat-form-field-suffix .mat-icon {
		font-size: 100%;
	}
	.mat-form-field-suffix {
		top: 0;
	}

}

.fused-inputs {
	display: flex;
	.first-fused-input.mat-form-field-appearance-outline {
		 .mat-form-field-outline-end {
			border-radius: 0;
		}
	}
	.second-fused-input.mat-form-field-appearance-outline {
		 .mat-form-field-outline-start {
			border-radius: 0;
		}
	}
}

.credit-card-preview {

	&.mat-form-field-appearance-outline {
		.mat-form-field-suffix {
			top: 0.75rem;
		}
	}
	.mat-icon {
		width: 4rem;
		height: inherit;
	}
}

$formControls: common-input, common-address, common-onboarding, common-select, common-social-security;
@each $control in $formControls {
	#{$control} .mat-form-field-appearance-outline .mat-form-field-wrapper
	{
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

mat-form-field.mat-form-field-appearance-outline .mat-form-field-outline {
    color: $nbkc-dark-gray;
}

.mat-form-field-label {
	color: $nbkc-dark-gray !important;
}


mat-form-field.mat-form-field-disabled.mat-form-field-appearance-outline  .mat-form-field-outline {
    color: $light-gray;
}

mat-label {
    color: $nbkc-dark-gray;
}
