@import 'colors';

.mat-mdc-button-base{
	&.rounded-material-button {
		border-radius: 200px;
	}
}

.mat-mdc-button {
	line-height: inherit !important;
}

.mat-mdc-raised-button.mat-button-disabled {
	background-color: $light-gray !important;
}
