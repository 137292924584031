@import 'colors';

.nbkc-mat-slide-toggle {
	label {
		display: flex;
		overflow: visible;
	}
}

.mat-slide-toggle-bar {
	background-color: $light-gray !important;
}
