
@font-face {
	font-family: 'GT Walsheim';
	font-style: normal;
	font-weight: 600;
	src: local('GT Walsheim Medium');
	src: local('GT-Walsheim-Medium'), url('~projects/common/src/assets/fonts/gt-walsheim/GT-Walsheim-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'GT Walsheim';
	font-style: normal;
	font-weight: 400;
	src: local('GT Walsheim Regular');
	src: local('GT-Walsheim-Regular'), url('~projects/common/src/assets/fonts/gt-walsheim/GT-Walsheim-Regular.woff2') format('woff2');
}
